import React, { Component } from 'react';
import icon_contacts from './Assets/img/icons/contacts.svg';
import icon_adress from './Assets/img/icons/adress.svg';
import icon_time from './Assets/img/icons/clock.svg';
import icon_phone from './Assets/img/icons/phone.svg';
import icon_mail from './Assets/img/icons/mail.svg';
import image_background from './Assets/img/background/bg_1.png';

import './Assets/styles/contacts.css';
import UtilsInformationConent from './Utils/utilsInformation';

class Contacts extends Component {
    render() {
        return (
            <>
                <UtilsInformationConent
                    id='contacts'
                    text_header='контакты'
                    icon_header={icon_contacts}
                    text_1='(пн-сб 08:00-17:00)'
                    text_2='+7 (960) 218-50-50'
                    text_3='+7 (814) 270-08-55'
                    text_4='bn_service@mail.ru'
                    image_1={icon_time}
                    image_2={icon_phone}
                    image_3={icon_phone}
                    image_4={icon_mail}
                    image_background={image_background}
                />
                <UtilsInformationConent
                    id='adress'
                    text_header='адрес'
                    icon_header={icon_adress}
                    text_0='Республика Карелия, г.Петрозаводск, ул.Заводская 2'
                    image_background={image_background}
                />
            </>
        );
    }
}

export default Contacts;