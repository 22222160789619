import React from 'react';
import '../Assets/styles/utils-header.css';
import UtilsHeader from '../Utils/utilsHeader';

const UtilsInformationConent = ({id, text_header, icon_header, text_0, text_1, text_2, text_3, text_4, image_0, image_1, image_2, image_3, image_4, image_background }) => {
    return (
        <div className='contacts' id={id}>
            <UtilsHeader text={text_header} image={icon_header} />
            <div className='contacts-container'>
                <div className='contacts-container-window'>
                    {text_0 && (
                        <div className='content-0'>
                            {image_0 && <img src={image_0} alt='#' className='icon' />} {text_0}
                        </div>
                    )}
                    {text_1 && (
                        <div className='content-1'>
                            {image_1 && <img src={image_1} alt='#'  className='icon' />} {text_1}
                        </div>
                    )}
                    {text_2 && (
                        <div className='content-contacts'>
                            {text_2 && (
                                <div className='content-2'>
                                    <img src={image_2} alt='#'  className='icon' /> <a href='tel:+7 (960) 218-50-50'>{text_2}</a>
                                </div>
                            )}
                            {text_3 && (
                                <div className='content-3'>
                                    <img src={image_3} alt='#'  className='icon' /> <a href='tel:+7 (814) 270-08-55'>{text_3}</a>
                                </div>
                            )}
                            {text_4 && (
                                <div className='content-4'>
                                    <img src={image_4} alt='#'  className='icon' /> <a href='mailto:bn_service@mail.ru'>{text_4}</a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <style>
                    {`
                    .contacts-container::before {
                        content: '';
                        position: absolute;
                        background-image: url(${image_background});
                        background-size: cover;
                        opacity: 0.2;
                        filter: blur(10px);
                        width: 100%;
                        height: calc(100% - 10vw);
                    }
                    `}
                </style>
            </div>
        </div>
    );
};

export default UtilsInformationConent;
