import React, { Component } from 'react';
import './Assets/styles/slider.css';
import { Carousel } from 'react-bootstrap';
import slide_1 from './Assets/img/slider/SLIDE_1.png'
import slide_2 from './Assets/img/slider/SLIDE_2.png'
import slide_3 from './Assets/img/slider/SLIDE_3.png'



class Slider extends Component {
    render() {
        return (
            <div className='carusel_shadow' id='main'>
            <Carousel>
                <Carousel.Item>
                    <img className='d-block w-100' src={slide_1} alt='#'/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className='d-block w-100' src={slide_2} alt='#'/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className='d-block w-100' src={slide_3} alt='#'/>
                </Carousel.Item>
            </Carousel>
            </div>
        );
    }
}

export default Slider;