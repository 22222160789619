import React from 'react';
import '../Assets/styles/information.css';



import { motion} from 'framer-motion';






const YtextAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2 },
    }),
}



const Information = ({ text, image, backgroundImage, position }) => {
    const isLeftPosition = position === 'left';

    

    const contentContainerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
    };

    
    return (
        <motion.div initial="hidden"
        whileInView="visible"
        viewport={{once: true}}
        custom={2} variants={YtextAnimation}>
        <div className="information-container">
            
                <div className='window'>
                    
                    <div style={contentContainerStyle} className='background-image'></div>
                    <div className='content-container'>
                        <div className={`content ${isLeftPosition ? 'left' : 'right'}`}>
                            <div className='text-container'>
                                
                                <span className="text">
                                    • {text}
                                </span>
                            </div>
                            <img className="foreground-image" src={image} alt="#" />
                        </div>
                    </div>
                    
                </div>
        </div>
        </motion.div>
    );
};

export default Information;

