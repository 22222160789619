import About from './Components/About ';
import Header from './Components/Header';
import InformationContent from './Components/InformationContent';
import Slider from './Components/Slider';
import Services from './Components/Services';
import Contacts from './Components/Contacts';
import Location from './Components/Location';
import Footer from './Components/Footer';
import './Components/Assets/styles/app.css';
import { Helmet } from 'react-helmet';





function App() {
  return (
    <>
      <Helmet>
        <title>Сервисный центр "УМ-Сервис" | Техническое обслуживание и ремонт грузовых автомобилей и спецтехники в Карелии</title>
      </Helmet>
      <Header />
      <Slider />
      <About />
      <InformationContent />
      <Services />
      <Contacts />
      <Location />
      <Footer />
      <div className='logos'>
        <label className='logos-copyright'>
          ©️ 2023 “ООО УМ СЕРВИС”
        </label>
        <label className='logos-developers'>
          разработка: IDLab.com
        </label>
      </div>
    </>
  );
}

export default App;
