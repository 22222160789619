import React, { useState, useEffect } from 'react';
import { Button, Container, Navbar, NavbarBrand, Nav } from 'react-bootstrap';
import './Assets/styles/header.css';

import logo_um from './Assets/img/logo/l1.png';
import logo_maz from './Assets/img/logo/l2.png';
import logo_jmz from './Assets/img/logo/l3.png';
import { ReactComponent as IconMain } from './Assets/img/icons/main.svg';
import { ReactComponent as IconAbout } from './Assets/img/icons/about.svg';
import { ReactComponent as IconUslugi } from './Assets/img/icons/uslugi.svg';
import { ReactComponent as IconContacts } from './Assets/img/icons/contacts.svg';
import { ReactComponent as IconAdress } from './Assets/img/icons/adress.svg';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHeaderOpen, setIsHeaderOpen] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            if (prevScrollPos > currentScrollPos) {
                setIsHeaderOpen(true);
            } else {
                setIsHeaderOpen(false);
            }
            if (currentScrollPos <= 70) {
                setIsHeaderOpen(true);
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const headerClass = `header ${isMenuOpen ? 'header-with-background' : ''}`;
    const headerOpen = `navbar ${isHeaderOpen ? 'navbar-open' : ''}`;

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className={headerOpen}>
                <Container>
                    <>
                        <NavbarBrand><img alt="#" src={logo_um} id='um' /></NavbarBrand>
                        <NavbarBrand><img alt="#" src={logo_maz} id='maz' /></NavbarBrand>
                        <NavbarBrand><img alt="#" src={logo_jmz} id='jmz' /></NavbarBrand>
                    </>
                    <Navbar.Toggle onClick={toggleMenu} id="responsive-navbar-nav" />
                </Container>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <div className={headerClass}></div>
                        <Button variant='primary' onClick={() => {
                            document.getElementById('main').scrollIntoView({ behavior: 'smooth' });
                        }}><label><IconMain />главная</label><span className='line'></span></Button>
                        <Button variant='primary' onClick={() => {
                            document.getElementById('about_us').scrollIntoView({ behavior: 'smooth' });
                        }}><label><IconAbout />о нас</label><span className='line'></span></Button>
                        <Button variant='primary' onClick={() => {
                            document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
                        }}><label><IconUslugi />услуги</label><span className='line'></span></Button>
                        <Button variant='primary' onClick={() => {
                            document.getElementById('contacts').scrollIntoView({ behavior: 'smooth' });
                        }}><label><IconContacts />контакты</label><span className='line'></span></Button>
                        <Button variant='primary' onClick={() => {
                            document.getElementById('adress').scrollIntoView({ behavior: 'smooth' });
                        }}><label><IconAdress />адрес</label><span className='line'></span></Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

