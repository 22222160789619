import React from 'react';
  import '../Assets/styles/utils-service-slider.css'
  import { Carousel } from 'react-bootstrap';
  import slide1 from "../Assets/img/icons/services/garantee.png"
  import slide2 from "../Assets/img/icons/services/c1.png"
  import slide3 from "../Assets/img/icons/services/transmission.png"
  import slide4 from "../Assets/img/icons/services/painting.png"
  import slide5 from "../Assets/img/icons/services/plug.png"
  import slide6 from "../Assets/img/icons/services/Adblue.png"
  import slide7 from "../Assets/img/icons/services/crane.png"
  import slide8 from "../Assets/img/icons/services/machine.png"
  import slide9 from "../Assets/img/icons/services/equipment.png"
  import slide10 from "../Assets/img/icons/services/tow-truck.png"







  


  import image_1_bg from '../Assets/img/background/bg_1.png';





  const UtilsServiceSlider = ({i1}) => {

    const contentContainerStyle = {
      backgroundImage: `url(${image_1_bg})`,
      backgroundSize: 'cover',
    };
    

    return (
      <div className='utils-service-slider' >
        <Carousel defaultActiveIndex={i1} >
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>гарантийное и постгарантийное техническое обслуживание и ремонт</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide1}/>
              </div>
            </div>
          </Carousel.Item>
  
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>капитальный ремонт двигателей </div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide2}/>
              </div>
            </div>
          </Carousel.Item>
  
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>капитальный ремонт коробок передач, раздаточных коробок, редукторов</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide3}/>
              </div>
            </div>
          </Carousel.Item>
  
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>кузовные, сварочные и покрасочные работы</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide4}/>
              </div>
            </div>
          </Carousel.Item>
  
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>диагностика и ремонт электрики </div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide5}/>
              </div>
            </div>
          </Carousel.Item>
  
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>отключение системы Adblue</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide6}/>
              </div>
            </div>
          </Carousel.Item>
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>ремонт и обслуживание кранов, кранов-манипуляторов, автогидроподъемников</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide7}/>
              </div>
            </div>
          </Carousel.Item>
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>расточно-наплавочные работы отверстий</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide8}/>
              </div>
            </div>
          </Carousel.Item>
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>установка дополнительного оборудования</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide9}/>
              </div>
            </div>
          </Carousel.Item>
  
          <Carousel.Item>
            <div className='slide-container'>
              <div className='slide-text'>услуги грузового эвакуатора</div>
  
              <div className='slide-img-container' >
                <div className='slide-bg' style={contentContainerStyle}></div>
                <img className='slide-img' src={slide10}/>
              </div>
            </div>
          </Carousel.Item>
         
        </Carousel>
      </div>
    );
  };
   export default UtilsServiceSlider;

