import React from 'react';
import '../Assets/styles/utils-header.css'

const UtilsHeader = ({ text, image }) => {
  return (
    <div className='utils-header'>
      <img className='utils-icon' src={image} />
      <div className='utils-text'>
          {text}
      </div>
    </div>
  );
};

export default UtilsHeader;