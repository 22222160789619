import React, { Component } from 'react';
import './Assets/styles/services.css'

import icon_services from "./Assets/img/icons/uslugi.svg"
import UtilsHeader from './Utils/utilsHeader';
import UtilsServiceSlider from './Utils/utilsServiceSlider';
import { motion } from 'framer-motion';


const YtextAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: {type:"spring", delay: custom * 0.3 },
    }),
}





class Services extends Component {
    render() {
        return (
            <div className='services-container' id='services'>
                <UtilsHeader text='наши услуги' image={icon_services} />
                    <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                     className='services-content'>
                        <motion.div 
                        custom={2} variants={YtextAnimation}
                        className='w100'>
                        <UtilsServiceSlider i1={0} />
                        </motion.div>
                        <motion.div 
                        
                        
                        custom={3} variants={YtextAnimation}
                        className='w100'>
                        <UtilsServiceSlider i1={1}   />
                        </motion.div>
                        <motion.div 
                        
                        
                        custom={4} variants={YtextAnimation}
                        className='w100'>
                        <UtilsServiceSlider i1={2}  />
                        </motion.div>
                        <motion.div 
                       
                       
                        custom={5} variants={YtextAnimation}
                        className='w100 w100_hidden'>
                        <UtilsServiceSlider i1={3} />
                        </motion.div>

                        <motion.div 
                        
                       
                        custom={6} variants={YtextAnimation}
                        className='w100 w100_hidden'>
                        <UtilsServiceSlider i1={4}  />
                        </motion.div>

                        <motion.div 
                        
                        custom={7} variants={YtextAnimation}
                        className='w100 w100_hidden'>
                        <UtilsServiceSlider i1={5} />
                        </motion.div>
                    </motion.div>
                
            </div>
        );
    }
}

export default Services;