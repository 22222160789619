import React, { Component } from 'react';
import '../Components/Assets/styles/about.css'

import {motion} from 'framer-motion';


const textAnimation ={
    hidden: {
        x:-100,
        opacity:0,
    },
    visible: custom=> ({
        x:0,
        opacity:1,
        transition: {delay: custom * 0.2},
    }),
}

const RtextAnimation ={
    hidden: {
        x: 100,
        opacity:0,
    },
    visible: custom=> ({
        x:0,
        opacity:1,
        transition: {delay: custom * 0.2},
    }),
}


class About extends Component {
    render() {
        return (
            <div className='hidden' id='about_us'>
            <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{amount: 0.5}}
            className='about'>
                <div className='about_header'><motion.h1 custom={1} variants={textAnimation} className='about_headerH1'>o нашей компании</motion.h1></div>
                <div className='about_text'>
                <motion.div
                 initial="hidden"
                 whileInView="visible"
                 viewport={{amount: 0.5}}
                 custom={2} variants={RtextAnimation}  >На протяжении многих лет мы оказываем большой спектр услуг по ремонту
                    и обслуживанию различной спецтехники и коммерческого транспорта на
                    территории Республики Карелия, Мурманской, Вологодской и
                    Архангельской областей. </motion.div>
                </div>

            </motion.div>
            </div>

        );
    }
}

export default About;