import React, { Component } from 'react';
import Information from './Utils/Information';

import image_1 from './Assets/img/content/auto_1.png';
import image_1_bg from './Assets/img/background/bg_auto_1.png';
import image_2 from './Assets/img/content/auto_2.png';
import image_2_bg from './Assets/img/background/bg_auto_2.png';
import image_3 from './Assets/img/content/auto_3.png';
import image_3_bg from './Assets/img/background/bg_auto_3.png';






class InformationContent extends Component {
    render() {
        return (
            <div className='information-history'>
                <Information
                    text="Мы трудимся, помогая владельцам техники поддерживать ее в исправном
          техническом состоянии с 2012 года."
                    image={image_1}
                    backgroundImage={image_1_bg}
                    position='right'
                /> 
             
                <Information
                    text="Со дня основания пройден большой путь 
                    по улучшению технологий производимых работ, оснащению  и подбору первоклассных специалистов."
                    image={image_2}
                    backgroundImage={image_2_bg}
                    position='left'
                />
                <Information
                    text="На сегодняшний день мы занимаем одну из лидирующих позиций в 
                    сфере оказания услуг по обслуживанию и ремонту коммерческой техники в 
                    Республике Карелия."
                    image={image_3}
                    backgroundImage={image_3_bg}
                    position='right'
                />
            </div>
        );
    }
}

export default InformationContent;