import React, { Component } from 'react';
import '../Components/Assets/styles/location.css'
import UtilsHeader from './Utils/utilsHeader';
import icon_location from '../Components/Assets/img/icons/map.svg'

import img_content1 from '../Components/Assets/img/background/bg_loc_1.png'
import img_content2 from '../Components/Assets/img/background/bg_loc_2.png'
import img_content3 from '../Components/Assets/img/background/bg_loc_3.png'


class Location extends Component {
    render() {
        return (
            <div>
                <UtilsHeader text ="как нас найти" image={icon_location}/>
                <div className='location-container'>
                    
                    
                    <div className='location-content'>
                        <div className='content-text'>вид сверху</div>
                        
                            <img className='content-img' src={img_content2}/>
                        
                    </div>

                    <div className='location-content'>
                        <div className='content-text'>главное здание</div>
                        
                        <img className='content-img' src={img_content3}/>
                       
                    </div>

                    <div className='location-content'>
                        <div className='content-text'>вход</div>
                        
                        <img className='content-img' src={img_content1}/>
                     
                        </div>
                  
                    
                </div>
                
            </div>
                
            
        );
    }
}

export default Location;