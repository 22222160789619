import React from 'react';
import './Assets/styles/footer.css';
import icon_phone from './Assets/img/icons/phone.svg';
import icon_mail from './Assets/img/icons/mail.svg';
import icon_time from './Assets/img/icons/clock.svg';

export default function Footer() {
    return (
        <>
            <iframe className='footer-map-mobile' src="https://yandex.ru/map-widget/v1/?um=constructor%3A54c94c26f5bf5f5be43127865d73e4718179d0b7d2c66c574adae74a6aada2b6&amp;source=constructor" width="564" height="425" frameBorder="0"></iframe>

            <div className='Footer'>
                <iframe className='footer-map' src="https://yandex.ru/map-widget/v1/?um=constructor%3A54c94c26f5bf5f5be43127865d73e4718179d0b7d2c66c574adae74a6aada2b6&amp;source=constructor" width="564" height="425" frameBorder="0"></iframe>
                <div className='footer-information'>
                    <label className='footer-label'>информация</label>
                    <div className='footer-content'>
                        <h1 className='footer-text'><img className='footer-icon' src={icon_phone} />+7 (960) 218-50-50</h1>
                        <h1 className='footer-text'><img className='footer-icon' src={icon_phone} />+7 (814) 270-08-55</h1>
                        <h1 className='footer-text'><img className='footer-icon' src={icon_mail} />bn_service@mail.ru</h1>
                        <h1 className='footer-text'><img className='footer-icon' src={icon_time} />(пн-сб 08:00-17:00)</h1>
                    </div>
                </div>
                <div className='footer-services'>
                    <label className='footer-label'>наши услуги</label>
                    <ul className='footer-content-scroll'>
                        <li className='footer-text-list'>капитальный ремонт двигателя</li>
                        <li className='footer-text-list'>диагностика и ремонт электрики</li>
                        <li className='footer-text-list'>гарантийное обслуживание и ремонт</li>
                        <li className='footer-text-list'>ремонт коробок передач, редукторов и раздаточных коробок</li>
                        <li className='footer-text-list'>кузовые, сварочные и покрасочные работы</li>
                        <li className='footer-text-list'>отключение системы Adblue</li>
                        <li className='footer-text-list'>ремонт и обслуживание кранов, автогидроподъемников, кранов-манипуляторов</li>
                        <li className='footer-text-list'>расточно-наплавочные работы отверстий</li>
                        <li className='footer-text-list'>установка дополнительного оборудования</li>
                        <li className='footer-text-list'>услуги грузового эвакуатора</li>
                    </ul>
                </div>
                <div className='footer-partners'>
                    <label className='footer-label'>наши партнеры</label>
                    <ul className='footer-content-scroll'>
                        <li className='footer-text-list'>ПАО “Россети Северо-Запад”</li>
                        <li className='footer-text-list'>АО “Сегежский ЦБК”</li>
                        <li className='footer-text-list'>ООО “Карельский экологический оператор” (КЭО)</li>
                        <li className='footer-text-list'>АО “Кондопожское лесопромышленное хозяйство”</li>
                        <li className='footer-text-list'>Муниципальное унитарное предприятие Петрозаводские энергетические системы (МУППЭС)</li>
                        <li className='footer-text-list'>Петрозаводские коммунальные системы</li>
                        <li className='footer-text-list'>Карелприрод ресурс</li>
                        <li className='footer-text-list'>ПАО “ТГК-1”, ООО “Нефтегазстрой”</li>
                        <li className='footer-text-list'>ООО “Городской транзит”</li>
                        <li className='footer-text-list'>Главное управление МЧС России по Республике Карелия</li>
                    </ul>
                </div>
            </div>
        </>
    )
}